//react
import React, { useContext,useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import {
  Map,
  useApiIsLoaded,
  useMapsLibrary,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";

//mui
import { Button, TextField, Switch, FormGroup, FormControlLabel,TableCell, CircularProgress, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';

//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
import TextInput from '../../../common/TextInput'

import { GoogleMap,LoadScript,Marker } from '@react-google-maps/api';
import { Delete, EditLocation } from '@mui/icons-material';


const ManageLocationDrawer = (companyId) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [companiesData, setCompaniesData] = useState([]);



  const [newLocation, setNewLocation] = useState({
    title: "",
    address: "",
    phone: "",
    email: ""
  })

  const [createBlogObj, setCreateBlogObj] = useState({ address: "South Africa", title: "", post: "", pageTitle: "", metaDescription: "", metaKeywords: "", isVideo: "no", videoUrl: "" });

  const [position, setPosition] = useState();
  const geocoding = useMapsLibrary("geocoding");
  const isLoaded = useApiIsLoaded();

  useEffect(() => {
    async function getAddressPosition() {
      if (isLoaded && geocoding) {
        const geocoder = new geocoding.Geocoder();
        const response = await geocoder.geocode({ address: createBlogObj.address });
        setPosition(response.results[0].geometry.location);
      }
    }

    void getAddressPosition();
  }, [createBlogObj.address, geocoding, isLoaded]);

  const submitCreateBlog = () => {

    setLoading(true)

    const { snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          getAllBlogs(data.id)
        }
      })

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const getAllBlogs = (id) => {

    try {

      fetch(`${process.env.REACT_APP_API_URI}/v3/blogs/${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            setLoading(false)
            openUploadImageModal(id, data.data[0])
            drawerCtx.setDetails(null, data.data[0]);
            drawerCtx.closeDrawer();
          }
        }).catch(err => {
          console.error(err)
        });

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: id, blog: blog });
    modalCtx.openModal();
  }


  const center = {
    lat: -3.745,
    lng: -38.523
  };
  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  
  useEffect(() => {
    fetchLocations();
  }, []);
  const fetchLocations = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/companies/977/locations`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authCtx.token}` },
      });
      const data = await response.json();
  
      // console.log('Fetched data:', data);
  
 
      if (Array.isArray(data)) {
        setLocations(data);
      } else if (data.locations && Array.isArray(data.locations)) {
        setLocations(data.locations);
      } else {
        // console.error('Unexpected response structure:', data);
        setLocations([]);  
      }
    } catch (error) {
      // console.error('Error fetching locations:', error);
      setLocations([]);  
    } finally {
      setLoading(false);
    }
  }; 

  
  // setCompaniesData(companyId.drawerDetails.locations)
  
  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Manage Locations</h1>
        </header>

        <section style={{ padding: '1rem', display:'flex', flexDirection:'column' , gap:'2rem' }}>
      
      <div className='locations-textfield'>
      <div className='textfields-container'>
        <TextField name="title" label="Location Title" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="address" label="Add Address" value={createBlogObj?.address} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="contact-person" label="Phone" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="email" label="Email" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        
        </div>
        <Button className='location-add-button'>Add</Button>
      </div>
        
        <div className='map-container'>
          <Map
            isMarkerShown={true}
            zoom={15}
            center={position}
            mapId={'nhbvasjd8978213clas'}
            disableDefaultUI
          >
            <AdvancedMarker position={position} draggable={false} >
              <Pin />
            </AdvancedMarker>
          </Map>
        </div>

        <div>
          <TableContainer  className='company-table' >
            <Table>
              <TableHead>
                <TableRow>

              {/* <TableCell className='company-head-cell' align="left">&nbsp;</TableCell> */}
                <TableCell align="left" className='company-head-cell'>Title</TableCell>
                <TableCell align="left" className='company-head-cell'>Address</TableCell>
                <TableCell align="left" className='company-head-cell'>Longitude</TableCell>
                <TableCell align="left" className='company-head-cell'>Latitude</TableCell>
                <TableCell align="left" className='company-head-cell'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody  className='company-table-body'>
{companyId.drawerDetails.locations.map((location) => (
                  <TableRow key={location.id}>
                    <TableCell align="left" className='company-cell'>{location.title}</TableCell>
                    <TableCell align="left" className='company-cell'>{location.address}</TableCell>
                    <TableCell align="left" className='company-cell'>{location.longitude}</TableCell>
                    <TableCell align="left" className='company-cell'>{location.latitude}</TableCell>
                    <TableCell align="left" className='company-cell'>
                      <Button ><EditLocation/></Button>
                      <Button ><Delete /></Button>
                    </TableCell>
                  </TableRow>
                ))  

}
              </TableBody>
              <TableBody>

              </TableBody>
            </Table>

          </TableContainer>
        </div>
        
        <div className="double-column-form aligned-left margin-top">
          <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Close</Button>
            {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default ManageLocationDrawer;
