import { useEffect ,useContext} from 'react';
import { useTheme } from '@emotion/react';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, MoreVertRounded } from '@mui/icons-material';
import { Checkbox, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'

import CustomButton from '../../common/CustomButton';
import DrawerContext from '../../context/DrawerContext';
import ModalContext from '../../context/ModalContext';
import TextInput from '../../common/TextInput';
import Fade from '@mui/material/Fade';

function TablePaginationActions(props) {
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState([])
  
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [data,setData]=useState([]);  
  const [searchInput, setSearchInput] = useState('');
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to manage the menu anchor
  const [selectedRow, setSelectedRow] = useState(null); // State to track which row is selected
  const [filterValue, setFilterValue] = useState('');
  const [displayedData, setDisplayedData] = useState(data);

  // Handle menu opening
  const open = Boolean(anchorEl);
  const  handleClick = (event,data) => {
    setAnchorEl(event.target);
    setSelectedRow(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetch('https://api.delivait.com/v3/agents')
      .then(response => response.json())
      .then(data => setData(data.data));
  }, [drawerCtx]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showStatus = (status) => {
    if(status == "pending") {
      return <div className='status status-pending'>Pending</div>;
    } else if(status == "invite_sent") {
      return <div className='status status-invite'>Invite Sent</div>;
    } else if(status == "active") {
      return <div className='status status-active'>Active</div>;
    } else if(status == "suspended") {
      return <div className='status status-suspended'>Suspended</div>;
    }
  }

  useEffect(() => {
    const filteredData = data?.filter((data) => (
      data?.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
      (data?.keywords?.toLowerCase().includes(searchInput.toLowerCase())))
    );
    setDisplayedData(filteredData);
  }, [filterValue, searchInput, data])

  function toggleDrawerHandler(drawer, user) {
    handleClose();
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };
  const open2 = Boolean(anchorElFilter);

  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);
    setFilterValue(value)
  };
  
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  return (
    <>
      <div className="agents-container">
        <div className='header-section'>
          <span className='title'>AGENTS</span>
          <div className='header-items-div'>
          <TextInput value={searchInput} onChange={(e) => { setSearchInput(e.target.value); console.log(e.target.value) }} placeholder='Search' />
          <div>
            {/* <CustomButton onClick={handleClickFilter} className={'deliva-btn-bordered'} >
              Filter By: {filterValue !== '' ? filterValue : 'All'}
            </CustomButton> */}
            <Menu id="basic-menu" anchorEl={anchorElFilter} open={open2} onClose={() => handleCloseFilter('')}>
              <MenuItem onClick={() => handleCloseFilter('agent')}>Agent</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('')}>All</MenuItem>
            </Menu>
          </div>
          <CustomButton  onClick={() => { toggleDrawerHandler('add-agent', null) }}>+ Add Agent</CustomButton>
        </div>
      </div>
        <TableContainer component={Paper} className='agent-table'>
          <Table sx={{ minWidth: 500 }}>
          <TableHead className='agent-table-head'>
            <TableRow>
              <TableCell align="left" className="agent-head-cell">Name</TableCell>
              <TableCell align="left" className="agent-head-cell">Email & Phone</TableCell>
              <TableCell align="left" className="agent-head-cell">Status</TableCell>
              <TableCell align="left" className="agent-head-cell">Location</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {(
                rowsPerPage > 0 ? displayedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : displayedData).map((data) => (
                  <TableRow key={data.id}>
                    <TableCell style={{ width: 150 }} align="left"><span>{data.firstName} {data.lastName}</span></TableCell>
                    <TableCell style={{ width: 120 }} align="left">{data.phone}<br/>{data.email}</TableCell>
                    <TableCell style={{ width: 90 }} align="left">{showStatus(data.status)}</TableCell>
                    <TableCell style={{ width: 160 }} align="left">{data.location}</TableCell>
                    <TableCell style={{ width: 10 }} align="left">
                      <MoreVertRounded aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" null aria-expanded={open ? 'true' : undefined} onClick={(e) => { handleClick(e, data); }} />                 
                    </TableCell>
                  </TableRow>
                )
              )}

              {
                emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Menu id={`fade-menu$`} MenuListProps={{ 'aria-labelledby': `fade-button`, }} anchorEl={anchorEl} open={open} onClose={handleClose} TransitionComponent={Fade} >
          <MenuItem onClick={() => toggleDrawerHandler('edit-agent', selectedRow)}>Edit</MenuItem>
        </Menu>
        <TableContainer component={Paper} className='agent-table-pagination'>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[ 5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
