//react
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';



//mui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, Tabs, Tab, RadioGroup, FormLabel, Radio, Checkbox, Autocomplete, createFilterOptions, Chip } from "@mui/material";

import {
  Map,
  useApiIsLoaded,
  useMapsLibrary,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";

//context
import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import ModalContext from "../../../context/ModalContext";

//component
import TextInput from "../../../common/TextInput";
import { set } from "firebase/database";
const filter = createFilterOptions();

const CreateEmergencyServiceDrawer = ({drawerDetails}) => {

  const { comapniesNameData, type } =  drawerDetails;

  const Details = comapniesNameData || [];

  const [local,setLocal]=useState(type=="local");

  const [Countries,setCountries]=useState([]);
  const [countryValue, setCountryValue] = useState(null);
  // console.log(Details, "this is our drawer details");
  
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [checkboxState, setCheckBoxState] = React.useState({
    phoneCheckbox: false,
    navigateCheckbox: false,
  });
  const {  phoneCheckbox, navigateCheckbox } = checkboxState;

  const [isCreating, setisCreating] = useState(false);
  const [allowLocation, setAllowLocation] = useState(false);

  const [companiesData, setCompaniesData] = useState([]);
  const [companiesName, setCompaniesName] = useState([]);
  const [companyValue, setCompanyValue] = React.useState(null);
  const [erServiceTitleValue, setERServiceTitleValue] = React.useState(null);

  const [value, setValue] = useState("details");
  const [keywords, setKeywords] = useState();

  const [locationValue, setLocationValues] = useState(null);

  const [originalCompaniesList, setOriginalCompaniesList] = useState(null);

  const [loc, setLoc] = useState({address: "South Africa", position: undefined});
  const geocoding = useMapsLibrary("geocoding");
  const isLoaded = useApiIsLoaded();

  const [loading, setLoading] = useState(false);

  const [createBlogObj, setCreateBlogObj] = useState({
    snippet: "",
    title: "",
    post: "",
    pageTitle: "",
    metaDescription: "",
    metaKeywords: "",
    isVideo: "no",
    videoUrl: "",
  });

   const [createEmergencyServiceObj, setEmergencyServiceObj] = useState({
    title: "",
    company: "",
    priority: "",
    phone: "",
    description: "",
    keywords: "no",
    locations: [],
  });

  const [categoryValue, setCategoryValue] = React.useState('');

  const emergencyContacts = [
    { Category: 'National Emergency Number', title: 'NATIONAL EMERGENCY HOTLINE', Priority: 1, type: 'security' },
    { Category: 'Ambulance Service (Public)', title: 'PUBLIC AMBULANCE', Priority: 5, type: 'medical' },
    { Category: 'Ambulance Service (Private)', title: 'PRIVATE AMBULANCE (PAID)', Priority: 6, type: 'medical' },
    { Category: 'Animal Hospital (or Veterinarian)', title: 'VETERINARIAN', Priority: 19, type: 'animals' },
    { Category: 'Air Ambulance', title: 'AIR AMBULANCE', Priority: 7, type: 'medical' },
    { Category: 'Disaster Management', title: 'DISASTER MANAGEMENT', Priority: 15, type: 'security' },
    { Category: 'Fire Brigade', title: 'FIRE FIGHTERS', Priority: 4, type: 'fire' },
    { Category: 'Helpline (Childline)', title: 'CHILDREN’S HELPLINE', Priority: 20, type: 'security' },
    { Category: 'Helpline (Rapeline)', title: 'RAPE ASSISTANCE HELPLINE', Priority: 21, type: 'security' },
    { Category: 'Helpline (Depression & Anxiety)', title: 'DEPRESSION & ANXIETY HELPLINE', Priority: 22, type: 'medical' },
    { Category: 'Helpline (Suicide Prevention)', title: 'SUICIDE PREVENTION HELPLINE', Priority: 23, type: 'medical' },
    { Category: 'Helpline (Poison)', title: 'POISON HELPLINE', Priority: 24, type: 'medical' },
    { Category: 'Hospital (Public)', title: 'PUBLIC HOSPITAL', Priority: 8, type: 'medical' },
    { Category: 'Hospital (Private)', title: 'PRIVATE HOSPITAL (PAID)', Priority: 9, type: 'medical' },
    { Category: 'Hospital (Military)', title: 'MILITARY HOSPITAL (PAID)', Priority: 10, type: 'medical' },
    { Category: 'Humanitarian Organization', title: 'HUMANITARIAN RELIEF', Priority: 33, type: 'rescue' },
    { Category: 'Municipal Emergency Number', title: 'MUNICIPAL EMERGENCIES', Priority: 34, type: 'security' },
    { Category: 'Neighborhood Watch', title: 'NEIGHBORHOOD WATCH', Priority: 28, type: 'security' },
    { Category: 'Pest Control (Baboon Control)', title: 'BABOON CONTROL', Priority: 25, type: 'security' },
    { Category: 'Pest Control (Beekeeper)', title: 'BEEKEEPER', Priority: 26, type: 'security' },
    { Category: 'Pest Control (Snake Handler)', title: 'SNAKE HANDLER', Priority: 27, type: 'security' },
    { Category: 'Police Service', title: 'POLICE', Priority: 2, type: 'security' },
    { Category: 'Rescue (Mountain)', title: 'MOUNTAIN RESCUE', Priority: 12, type: 'rescue' },
    { Category: 'Rescue (Wilderness / Offroad)', title: 'OFFROAD & WILDERNESS RESCUE', Priority: 13, type: 'rescue' },
    { Category: 'Rescue (Sea)', title: 'SEA RESCUE', Priority: 14, type: 'rescue' },
    { Category: 'Rescue (Lifesavers)', title: 'LIFESAVERS', Priority: 15, type: 'rescue' },
    { Category: 'Shelter (Safehouse)', title: 'SAFEHOUSE', Priority: 17, type: 'shelters' },
    { Category: 'Shelter (Night Shelter)', title: 'NIGHT SHELTER', Priority: 18, type: 'shelters' },
    { Category: 'Tipline (Crime)', title: 'REPORT CRIME', Priority: 29, type: 'security' },
    { Category: 'Tipline (Fraud)', title: 'REPORT FRAUD', Priority: 30, type: 'security' },
    { Category: 'Tipline (Human Trafficking)', title: 'REPORT HUMAN TRAFFICKING', Priority: 31, type: 'security' },
    { Category: 'Tipline (Shark Sightings)', title: 'REPORT SHARK SIGHTINGS', Priority: 32, type: 'security' },
    { Category: 'Traffic Department', title: 'TRAFFIC DEPARTMENT', Priority: 3, type: 'security' },
    { Category: 'Veterinarian (or Animal Hospital)', title: 'VETERINARIAN', Priority: 19, type: 'animals' },
    { Category: 'Volunteer Firefighters', title: 'VOLUNTEER FIRE FIGHTERS', Priority: 11, type: 'fire' },
  ];

  const states = [
    { id: 1, name: "Eastern Cape" },
    { id: 2, name: "Free State" },
    { id: 3, name: "Gauteng" },
    { id: 4, name: "KwaZulu-Natal" },
    { id: 5, name: "Limpopo" },
    { id: 6, name: "Mpumalanga" },
    { id: 7, name: "Northern Cape" },
    { id: 8, name: "North West" },
    { id: 9, name: "Western Cape" },
  ];

  useEffect(() => {
    async function getAddressPosition() {
      if (isLoaded && geocoding) {
        const geocoder = new geocoding.Geocoder();
        const response = await geocoder.geocode({ address: loc.address });
        setLoc(p => ({...p,position: response.results[0].geometry.location}));
      }
    }
    void getAddressPosition();
  }, [loc.address, geocoding, isLoaded]);
  
  

  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };
  
  const handleCheckboxChange = (event) => {
    setCheckBoxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };


  const createEmergencyService = () => {

    setisCreating(true);

    const {
         phone, description, locations
    } = createEmergencyServiceObj;

    const emergencyService = emergencyContacts.filter((contact)=> contact.title === erServiceTitleValue.title)[0];

    const title = emergencyService.title;
    const priority = emergencyService.Priority;
    const type = emergencyService.type;
    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify({
          priority: priority,
          companyId: companyValue?.id,
          emergencyService: type,
          title: title,
          description: description,
          keywords: keywords.join(" "),
          phone: phone,
          phoneBtn: phoneCheckbox ? "yes" : "no",
          navigateBtn: navigateCheckbox ? "yes" : "no",
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            // getAllBlogs(data.id);
            // drawerCtx.closeDrawer();
            setAllowLocation(true);
            setValue('location');
            setisCreating(false);
          }
        });
    } catch (error) {
      console.log("error : ", error);
    }
  };


  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails("upload-fixed-image", {
      aspectRatio: 1.6,
      origin: "blog",
      id: id,
      blog: blog,
    });
    modalCtx.openModal();
  };

 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  

  const getAllCompanies = () => {    

    if(Details){
      console.log(Details, "this is compnay the data drawer details");
            const companiesList = Details.map(company => ({
              id: company.id,
              title: company.companyDetails.company,
              image: company.logo
            }));
  
            setCompaniesData(companiesList);
            setOriginalCompaniesList(Details);

    }else{
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            console.log(data?.data, "this is compnay the data");
            const companiesList = data?.data.map(company => ({
              id: company.id,
              title: company.companyDetails.company
            }));
  
            setCompaniesData(companiesList);
            setOriginalCompaniesList(data?.data);
  
          }
        }).catch(err => {
          console.error(err)
          // setUpdatingStatus(false)
        });
    }

  }

  const getAllCountries= ()=>{
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/countries`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          // console.log(data?.data, "this is country");
         
          setCountries(data?.data);

        }
      }).catch(err => {
        console.error(err)
      });

  }


  // console.log(companiesName,"this is company nanes")


  useEffect(()=>{
    getAllCompanies();
    getAllCountries();
  },[])

  useEffect(()=>{
    const locationData = originalCompaniesList?.filter((company)=> company.companyDetails.company === companyValue.title)[0].locations;
    // console.log(locationData, "this is our location data");
    setLocationValues(locationData);
  },[companyValue])

  useEffect(()=>{
    let  columns = [
      { field: 'title', headerName: 'Title', width: 150 },
      { field: 'address', headerName: 'Address', flex: 1 },
      { field: 'longitude', headerName: 'Longitude', width: 150 },
      { field: 'latitude', headerName: 'Latitude', width: 150 },
    ];
    
    let rows = locationValue?.map((location, index) => ({
      id: location.id,
      title: location.title,
      address: location.address,
      longitude: location.longitude,
      latitude:location.latitude,
    }));

    setColumns(columns || []);
    setRows(rows || []);
  },[locationValue])

  const [state, setState] = useState("");
  const handleStateChange = (event) => {
    setState(event.target.value);
  };



  return (
    <div className="drawer-container">
      <div className="drawer">
        <header >
          <h1 data-aos="fade-right" data-aos-delay="500">
            Create Emergency Service
          </h1>
          
        </header>
        

        <section
          style={{
            padding: "0rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="black"
            indicatorColor="primary"
            style={{ borderBottom: "1px solid #ccc" }}
            aria-label="secondary tabs example"
          >
            <Tab className="tabs-card" value="details" label="DETAILS" />
            {local&&(
            <Tab
            //  disabled={!allowLocation} 
              className="tabs-card" value="location" label="LOCATIONS" />
              )}
          </Tabs>
          

          <div style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}>
          {value === "details" ? (
            <>
             <Autocomplete
              className="full-width-column"
                value={companyValue}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setCompanyValue({
                      title: newValue,
                    });

                    

                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCompanyValue({
                      title: newValue.inputValue,
                    });

                  } else {
                    setCompanyValue(newValue);
                  
                  }

                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-list"
                options={companiesData}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderOption={(props, option) => <li className="" {...props}><img className="company-logo-img" src={option.image} alt="Company Logo"/> {option.title}</li>}
                freeSolo
                renderInput={(params) => (
                  <TextField className="full-width-column" fullWidth {...params} label="Company" />
                )}
              />

            <Autocomplete
              className="full-width-column"
                value={erServiceTitleValue}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setERServiceTitleValue({
                      title: newValue,
                    });

                    

                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setERServiceTitleValue({
                      title: newValue.inputValue,
                    });

                  } else {
                    setERServiceTitleValue(newValue);
                  
                  }

                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-list"
                options={emergencyContacts}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderOption={(props, option ) => {

                const {'data-option-index': optionIndex}  = props;
                console.log(optionIndex, "this is our option index");

                  if (optionIndex === 0) {
                    return (<> <div className="drawer-option-title sticky-top"><span>Title</span><span>Priority</span></div>
                      <div  {...props} className="drawer-option-title"  ><span>{option.title}</span><span>{option.Priority}</span> </div></>)

                  }

                return (<div  {...props} className="drawer-option-title"  ><span>{option.title}</span><span>{option.Priority}</span> </div>)}}
                freeSolo
                renderInput={(params) => (
                  <TextField className="full-width-column" fullWidth {...params} label="Emergency Service" />
                )}
              />
              <div className="" style={{ width: "100%", display: "flex" }}>
                {/* <TextField
                  fullWidth
                  name="priority"
                  label="Priority"
                  value={createEmergencyServiceObj?.priority}
                  onChange={(e) =>
                    setEmergencyServiceObj((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                /> */}
                <TextField
                  fullWidth
                  name="phone"
                  label="Default Phone Number"
                  value={createEmergencyServiceObj?.phone}
                  onChange={(e) =>
                    setEmergencyServiceObj((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                />
              </div>
              <TextField
                name="description"
                label="Description"
                value={createEmergencyServiceObj?.description}
                onChange={(e) =>
                  setEmergencyServiceObj((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
             <Autocomplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                multiple
                id="keywords-list"
                options={[]}
                freeSolo
                value={keywords}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setKeywords( [...keywords, newValue] );
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setKeywords([...keywords, newValue.inputValue]);
                  } else {
                    setKeywords([...newValue]);
                    console.log("new value", newValue)
                  }
                }}
                renderTags={(value, getTagProps) =>
                  keywords.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))

                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant="outlined"
                    label="Keywords"
                  />
                )}
              />


              {!local&&(
              <div>
                <FormLabel id="category-radio-buttons-group-label">Category</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="category-radio-buttons-group-label"
                  name="category-radio-buttons-group"
                  value={categoryValue}
                  onChange={handleCategoryChange}
                >
                  <FormControlLabel value="regional" control={<Radio />} label="Regional" />
                  <FormControlLabel value="national" control={<Radio />} label="National" />
                </RadioGroup>
              </div>  
              )}   
              {categoryValue === "national" ? (
                <Autocomplete
                className="full-width-column"
                value={countryValue}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setCountryValue({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCountryValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setCountryValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
  
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }
  
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="country-list"
                options={Countries}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name; // Display only the country name
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li> // Display only the name
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="full-width-column"
                    fullWidth
                    {...params}
                    label="Country"
                  />
                )}
                />
              ) : categoryValue === "regional" ? (
              <div>
                  <FormControl fullWidth>
                    <InputLabel id="state-select-label">State</InputLabel>
                    <Select
                      labelId="state-select-label"
                      id="state-select"
                      value={state}
                      onChange={handleStateChange}
                      label="State"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <></>
              )}
              {local&&(
              <div className="flex-drawer">
                <FormControlLabel
                  control={
                    <Checkbox checked={phoneCheckbox} onChange={handleCheckboxChange} name="phoneCheckbox" />
                  }
                  label="Phone Button Visible"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={navigateCheckbox} onChange={handleCheckboxChange} name="navigateCheckbox" />
                  }
                  label="Navigate Button Visible"
                />
              </div>
              )}




              <div className="double-column-form column-full-width  margin-top">
                <button className="btn-primary" onClick={(e)=> drawerCtx.closeDrawer()}>Cancel</button>
                <button
                  disabled={!erServiceTitleValue || isCreating}
                  onClick={(e) => createEmergencyService()}
                  className="btn-primary"
                  
                >
                  {isCreating ? <CircularProgress/> : "Create"}
                </button>
                {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
              </div>
            </>
          ) : (
            <>
            <TextField name="address" label="Add Address" value={loc.address} onChange={(e) => setLoc((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />

            <div className="map-container">
              <Map
                isMarkerShown={true}
                zoom={15}
                center={loc.position}
                mapId={'aknsdasdq123asd'}
                disableDefaultUI
              >
                <AdvancedMarker position={loc.position} draggable={false} >
                  <Pin />
                </AdvancedMarker>
              </Map>
            </div>

            <div style={{ height: '80vh' }} className="" >
            <DataGrid
              rows={rows}
              columns={columns}
              // isRowSelectable={(params) => params.row.quantity > 50000}
              checkboxSelection
            />

            </div>
            </>
          )}
          </div>

          {/* <FormGroup>
          <FormControlLabel label="Video" control={<Switch />} onChange={e => handleVideoBoolean(e.target.checked)} />
        </FormGroup> */}
          {/* <TextField disabled={createBlogObj?.isVideo !== 'yes'} name="videoUrl" label="Video Url" value={createBlogObj?.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Video Url" /> */}
        </section>
      </div>
    </div>
  );
};

export default CreateEmergencyServiceDrawer;