import React, { useContext, useEffect, useState } from 'react'

import { useTheme } from '@emotion/react';
import { CheckBox, CheckBoxRounded, CheckCircle, CheckCircleOutline, Circle, CircleOutlined, FileOpenOutlined, FirstPage, FmdGoodTwoTone, KeyboardArrowLeft, KeyboardArrowRight, LastPage, LocationOn, MoreVertRounded, SearchOffOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, IconButton, Input, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import AuthContext from '../../context/AuthContext';

import MapIcon from "../../assets/img/company/location_icon.png";
import CouponIcon from "../../assets/img/company/coupons_icon.png";
import ModalContext from '../../context/ModalContext';
import DrawerContext from '../../context/DrawerContext';
import DescriptionIcon from '@mui/icons-material/Description';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import CustomButton from '../../common/CustomButton';
import TextInput from '../../common/TextInput';

function TablePaginationActions(props) {
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState([])
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}



export default function CustomPaginationActionsTable() {

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState(companiesData);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayedData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = (status) => {
    // switch (status) {
    //   case 'active':
    //     return <div className='status-active'>Active</div>
    //     break;

    //   case 'invite_sent':
    //     return <div className='status-invite'>Invite Sent</div>
    //     break;

    //   default:
    //     break;
    // }
    if (status == "pending") {
      return <div class='status-pending'>Pending</div>;
    } else if (status == "invite_sent") {
      return <div className='status-invite'>Invite Sent</div>;
    } else if (status == "active") {
      return <div className='status-active'>Active</div>;
    } else if (status == "suspended") {
      return <div class='status-suspended'>Suspended</div>;
    }
  }

 function isOlderThanOneYear(date) {
    if (!date) return true;
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return new Date(date) < oneYearAgo;
  }
  
  

  const getAllCompanies = () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        setLoading(false)
        console.log(data?.data, "this is the data");
        setCompaniesData(data?.data);


      }
    }).catch(err => {
      console.error(err)
      // setUpdatingStatus(false)
    });

  }


  useEffect(() => {
    getAllCompanies();
  }, [])


  const handleModal = (modal, data) => {
    handleClose();
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const filteredData = companiesData.filter((data) => (
  //     data.companyDetails.company.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     (data.companyDetails && data.companyDetails.keywords && data.companyDetails.keywords.toLowerCase().includes(searchInput.toLowerCase())))
  //   );
  //   setDisplayedData(filteredData);
  // }, [filterValue, searchInput, loading,companiesData])


  useEffect(() => {
    // Use a timeout to delay sorting
    const sortTimer = setTimeout(() => {
      const filteredData = companiesData.filter((data) => (
        data.companyDetails.company.toLowerCase().includes(searchInput.toLowerCase()) ||
        (data.companyDetails && data.companyDetails.keywords && data.companyDetails.keywords.toLowerCase().includes(searchInput.toLowerCase()))
      ));

      // Update tasksCompleted to "no" if the task was completed more than a year ago
      const updatedFilteredData = filteredData.map(company => {
        if (company.companyDetails.tasksCompleted === 'yes' && isOlderThanOneYear(company.companyDetails.lastTaskCompletedDate)) {
          return {
            ...company,
            companyDetails: {
              ...company.companyDetails,
              tasksCompleted: "no",
            },
          };
        }
        return company;
      });

      const sortedData = updatedFilteredData.sort((a, b) => {
        const isACompleted = a.companyDetails.tasksCompleted === 'yes' && !isOlderThanOneYear(a.companyDetails.lastTaskCompletedDate);
        const isBCompleted = b.companyDetails.tasksCompleted === 'yes' && !isOlderThanOneYear(b.companyDetails.lastTaskCompletedDate);

        // First, sort by completed status (incomplete tasks at top)
        if (isACompleted !== isBCompleted) {
          return isACompleted ? 1 : -1;
        }

        // If completion status is the same, sort by ID (highest ID at top)
        return b.companyDetails.id - a.companyDetails.id;
      });

      setDisplayedData(sortedData);
    }, 100); 


    return () => clearTimeout(sortTimer);
  }, [filterValue, searchInput, loading, companiesData]);


  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  }
  
  const open2 = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);
    setFilterValue(value)
  };
  // const handleTrickleStatusChange = async (companyId, currentStatus) => {
  //   try {
  
  //     const newStatus = !currentStatus;
  
   
  //     const response = await updateTrickleStatus(companyId, newStatus, authCtx.token);
  
  //     if (response?.status === "success") {
   
  //       setCompaniesData(prevData =>
  //         prevData.map(company =>
  //           company.id === companyId
  //             ? {
  //                 ...company,
  //                 companyDetails: {
  //                   ...company.companyDetails,
  //                   tasksCompleted: newStatus ? "yes" : "no",
  //                   lastTaskCompletedDate: newStatus ? new Date().toISOString() : null,
  //                 },
  //               }
  //             : company
  //         )
  //       );
  //     } else {
  //       console.error("Failed to update the company status. Check API response.");
  //     }
  //   } catch (error) {
  //     console.error("Failed to update trickle status:", error);
  //   }
  // };
  
  // const renderTasksCompletedStatus = (company) => {
  //   const { id, companyDetails } = company;
  //   const { tasksCompleted, lastTaskCompletedDate } = companyDetails;
  //   const isChecked = tasksCompleted === 'yes' && !isOlderThanOneYear(lastTaskCompletedDate);

  //   return (
  //     <Checkbox
  //     checked={isChecked}
  //     onChange={() => handleTrickleStatusChange(id, isChecked)}
  //     color="success"
  //     checkedIcon={<CheckCircle />}
  //     icon={<CircleOutlined />}
    
  //     />
  //   );
  // };


   async function updateTrickleStatus(companyId, status, token) {
    try {
      const response = await fetch(`https://api.delivait.com/v3/admin/company/${companyId}/updateTrickleStatus `, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + token },
        body: JSON.stringify({ status })
      });
     
      if (!response.ok) {
        throw new Error('Failed to update trickle status');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating trickle status:', error);
      throw error;
    }
  }
  
  


  const handleTrickleStatusChange = async (companyId, currentStatus) => {
    try {
      // Optimistically update the UI immediately
      setCompaniesData(prevData =>
        prevData.map(company =>
          company.id === companyId
            ? {
                ...company,
                companyDetails: {
                  ...company.companyDetails,
                  tasksCompleted: !currentStatus ? "yes" : "no",
                  lastTaskCompletedDate: !currentStatus ? new Date().toISOString() : null,
                },
              }
            : company
        )
      );
  
      // Proceed with API call
      const newStatus = !currentStatus;
      const response = await updateTrickleStatus(companyId, newStatus, authCtx.token);
  
      if (response?.status !== "success") {
        // If API call fails, revert the optimistic update
        setCompaniesData(prevData =>
          prevData.map(company =>
            company.id === companyId
              ? {
                  ...company,
                  companyDetails: {
                    ...company.companyDetails,
                    tasksCompleted: currentStatus ? "yes" : "no",
                    lastTaskCompletedDate: currentStatus ? new Date().toISOString() : null,
                  },
                }
              : company
          )
        );
        console.error("Failed to update the company status. Check API response.");
      }
    } catch (error) {
      // Revert the optimistic update in case of any error
      setCompaniesData(prevData =>
        prevData.map(company =>
          company.id === companyId
            ? {
                ...company,
                companyDetails: {
                  ...company.companyDetails,
                  tasksCompleted: currentStatus ? "yes" : "no",
                  lastTaskCompletedDate: currentStatus ? new Date().toISOString() : null,
                },
              }
            : company
        )
      );
      console.error("Failed to update trickle status:", error);
    }
  };
  
  const renderTasksCompletedStatus = (company) => {
    const { id, companyDetails } = company;
    const { tasksCompleted, lastTaskCompletedDate } = companyDetails;
    const isChecked = tasksCompleted === 'yes' && !isOlderThanOneYear(lastTaskCompletedDate);
  
    return (
      <Checkbox
        checked={isChecked}
        onChange={() => handleTrickleStatusChange(id, isChecked)}
        color="success"
        checkedIcon={<CheckCircle />}
        icon={<CircleOutlined />}
      />
    );
  };

  return (
    <>
      <div className="companies-container">
        <div className='header-section'>
          <span className='title'>COMPANIES</span>
          <div className='header-items-div'>
            <TextInput value={searchInput} onChange={(e) => { setSearchInput(e.target.value); console.log(e.target.value) }} placeholder='Search' />
            <div>
              <CustomButton onClick={handleClickFilter} className={'deliva-btn-bordered'} >
                Filter By :- {filterValue !== '' ? filterValue : 'All'}
              </CustomButton>
              <Menu id="basic-menu" anchorEl={anchorElFilter} open={open2} onClose={() => handleCloseFilter('')}>
                <MenuItem onClick={() => handleCloseFilter('agent')}>Agent</MenuItem>
                {/* <MenuItem onClick={()=>handleCloseFilter('rescue')}>Rescue</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('police')}>Police</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('shelters')}>Shelters</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('fire')}>Fire</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('pests')}>Pests</MenuItem>
                 */}
                <MenuItem onClick={() => handleCloseFilter('')}>All</MenuItem>
              </Menu>
            </div>
            <CustomButton onClick={() => { toggleDrawerHandler('add-company', null) }}>+ Add Company</CustomButton>
          </div>
        </div>
        <TableContainer component={Paper} className='company-table'>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow className='company-table-head'>
                <TableCell className='company-head-cell' align="left">&nbsp;</TableCell>
                <TableCell align="left" className='company-head-cell'>Company</TableCell>
                <TableCell align="left" className='company-head-cell'></TableCell>
                <TableCell align="left" className='company-head-cell'>Coupons</TableCell>
                <TableCell align="left" className='company-head-cell'>Locations</TableCell>
                <TableCell align="left" className='company-head-cell'>Setup</TableCell>
                <TableCell align="right" className='company-head-cell'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='company-table-body'>
              {loading ? <CircularProgress /> : (rowsPerPage > 0
                ? displayedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : displayedData
              ).map((data, index) => (

                <TableRow key={data.id} className='company-table-row' style={{ backgroundColor: index % 2 != 0 ? '#fafafc' : 'white' }}>
                  <TableCell className='company-table-cell' style={{ width: '50px', padding: 0, paddingLeft: '1rem' }} align="left">
                    <img style={{ height: '32px', width: '32px', borderRadius: '100%' }} src={data.logo}></img>
                  </TableCell>
                  <TableCell className='company-table-cell' style={{ flex: 1 }} align="left">

                    {data.companyDetails.company}

                  </TableCell>
                  <TableCell className='company-table-cell' style={{ width: '100px' }} align="left">
              {renderTasksCompletedStatus(data)}
            </TableCell>
                  <TableCell className='company-table-cell' style={{ width: '100px' }} align="left">
                    <div className='couponDiv'>
                      <img className='couponImg' src={CouponIcon}></img>

                      <span>{data.coupons.length}</span>
                    </div>
                  </TableCell>
                  <TableCell className='company-table-cell' style={{ width: '100px' }} align="left">
                    <div className='couponDiv'>
                      <img className='mapIcon' src={MapIcon}></img>
                      {/* <LocationOn/> */}
                      <span>{data.locations.length}</span>
                    </div>
                  </TableCell>
                  <TableCell className='company-table-cell' style={{ width: '140px' }} align="left">
                    <div className='status-container'>{handleStatus(data.companyDetails.status)}</div>

                  </TableCell>
                  <TableCell className='company-table-cell' style={{ width: '80px' }} align="left">
                    <div className='icons-container'>

                      <DescriptionIcon />
                      <MoreVertRounded aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true" null
                        aria-expanded={open ? 'true' : undefined} onClick={(e) => {
                          setSelectedCompany(data);
                          handleClick(e);
                        }} />


                    </div>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} className='agent-table-pagination'>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={displayedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Menu
        id={`fade-menu$`}
        MenuListProps={{
          'aria-labelledby': `fade-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handleModal('assign-agent', "id")}>Assign to Agent</MenuItem>
        <MenuItem onClick={() => {
          toggleDrawerHandler('manage-locations', selectedCompany);
          handleClose()
        }}>Manage Locations</MenuItem>
        <MenuItem onClick={() => {
          toggleDrawerHandler('manage-company', selectedCompany);
          handleClose()
        }}>Manage Company</MenuItem>
        <MenuItem onClick={handleClose}>Manage Contacts</MenuItem>
        <MenuItem onClick={() => handleModal('delete-companies', "id")}>Delete Company</MenuItem>
      </Menu>
    </>
  );
}