import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';

const DeleteTopicModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteTopic = async (topic) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${topic.blogId}/topic/${topic.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        modalCtx.closeModal();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="delete-modal">
      <p>Are you sure you want to delete this Topic?</p>
      <div className="footer">
        <button className="btn-primary-border" onClick={(e) => { handleCloseModal() }}>No</button>
        <button className="btn-primary" onClick={() => deleteTopic(modalDetails)}>Yes</button>
      </div>
    </div>
  )
}

export default DeleteTopicModal;
