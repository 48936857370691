import { Button } from "@mui/material";
import React, { useState } from "react"; // Added useState
import { useDropzone } from "react-dropzone";

function Dropzone({ onDrop, accept, open }) {
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const maxSize = 2 * 1024 * 1024; // Maximum size in bytes (e.g., 2MB)

  const handleDrop = (acceptedFiles, fileRejections) => {
    const validFiles = acceptedFiles.filter(file => file.size <= maxSize);
    if (validFiles.length) {
      onDrop(validFiles); // Pass valid files to the parent handler
      setErrorMessage(""); // Clear error message if valid files are present
    }

    if (fileRejections.length) {
      setErrorMessage("File size exceeds 2MB. Please upload a smaller image."); // Display error message
    }
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept,
    onDrop: handleDrop,
    maxSize, // React-dropzone will automatically reject files larger than this size
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="d-n-d">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection:"column" }} {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <p className="dropzone-content">
              Drag’ n’ drop Image here, or click to select Image
            </p>
          )}
          <button type="button" onClick={open} className="btn-primary">Click to select Image </button>
        </div>
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} {/* Error message */}
      <ul>{files}</ul>
    </div>
  );
}

export default Dropzone;
