import React, { useRef, useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, SnackbarContent } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { AddRounded, ArrowBackIos, ArrowBackRounded, ArrowForwardIosRounded, ArrowForwardRounded, CloseRounded, HighlightOffRounded, KeyboardArrowDownRounded, KeyboardBackspace, PublishRounded, Remove, ReplayRounded, Upload } from '@mui/icons-material';
import GetImage from '../../../common/GetImage';

import placeholder from '../../../assets/img/placeholder.png';
import backbutton from '../../../assets/img/drawer/back.svg';

// import Flower1 from '../../../assets/img/blog/edit/blog-edit-flower-1.png'
// import Flower2 from '../../../assets/img/blog/edit/blog-edit-flower-2.png'

import Logo from '../../../assets/img/deliva_icon.svg';
import DefaultImage from "../../../assets/img/blog/edit/default.jpg";

import ModalContext from '../../../context/ModalContext';

//mui
import { styled } from '@mui/material/styles';
import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';

const ScreenOrientationBtn = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 56, // Updated width
  height: 28, // Updated height
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)', // Adjusted for new width
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1ABC9C', // Updated background color when checked
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[700],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'dark' ? 0.3 : 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24, // Adjusted thumb size to fit new switch size
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24, // Updated border-radius
    backgroundColor: theme.palette.mode === 'dark' ? '#39393D' : '#C6C6C6',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));



const EditBlogDrawer = ({ drawerDetails }) => {

  // console.log(drawerDetails);


  //refs
  const titleRef = useRef(null);

  //context
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  //states
  const [open, setOpen] = useState(false);
  const [metaTagsOpen, setMetaTagsOpen] = useState(false);
  const [editorToolOpen, setEditorToolOpen] = useState(true);
  const [tagsTopicBarOpen, setTagsTopicBarOpen] = useState(true);
  const [blogVideoToggle, setBlogVideoToggle] = useState(false);

  // updating status states 
  const [blog, setBlog] = useState(drawerDetails);

  //Dummy Data
  const [topics, setTopics] = useState(blog.topics && blog.topics.length > 0 ? blog.topics : []);

  const [tags, setTags] = useState(blog.tags && blog.tags.length > 0 ? blog.tags : []);
  



  const [date, setDate] = useState(new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })); 
  const [imageDirection,setImageDirection] = useState(true);
  const [image, setImage] = useState(blog?.image || DefaultImage);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [savingChanges, setSavingChanges] = useState(false);

  const [createBlogObj, setBlogObj] = useState({
    snippet: blog?.snippet || "",
    title: blog?.title || "",
    post: blog?.post || "",
    slug: blog?.slug || blog?.id,
    pageTitle: blog?.pageTitle || "",
    metaDescription: blog?.metaDescription || "",
    metaKeywords: blog?.metaKeywords || "",
    isVideo: blog?.isVideo || "no",
    videoUrl: blog?.videoUrl || "",
    customUrl: blog?.customUrl || blog?.id,
  });
  const [authors, setAuthors] = useState(blog?.authors);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [author, setAuthor] = useState('');
  const [blogUrl, setBlogUrl] = useState(blog?.id);
  const [slug, setSlug] = useState(blog?.id);
  const [updateBlogStatus, setUpdateBlogStatus] = useState(false);
  const [statusClass, setStatusClass] = useState(() => {
    if (blog?.status === 'published') {
      return 'status-button published';
    } else {
      return 'status-button';
    }
  });
  const [editor, setEditor] = useState('myeditor disable');

  const quillRef = useRef();

  //styles
  const containerStyles = {
    width: imageDirection ? "460px" : "1076px",
    height: imageDirection ? "575px" : "300px", // Adjust height based on the aspect ratio
    // flexDirection: imageDirection ? "column" : "row",
  };

  //effect
  useEffect(() => {
    submitEditBlog()
  }, [modalCtx])

  useEffect(() => {

    if (titleRef && titleRef.current) {
      titleRef.current.innerText = blog?.title;
    }

  }, [blog]);

  const handleTitleInput = (e) => {
    setBlogObj((prev) => ({ ...prev, title: e.target.innerText }))
  }

  //functions
  const submitEditBlog = () => {

    setSavingChanges(true);


    if (authCtx.isLoggedIn) {
      const { title, snippet, post, slug, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog/' + blog?.id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          title,
          authors,
          snippet,
          post,
          slug,
          pageTitle,
          metaDescription,
          metaKeywords,
          isVideo,
          videoUrl
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        setSavingChanges(false);
        if (data.status === "success") {
          updateURL(blog?.id, slug);
          
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const updateURL = async (blogId, slug) => {

    // setUpdatingStatus(true)
    setUpdatingStatusId(blogId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateSlug', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ slug })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            getBlog();
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const closeDrawer = () => {
    console.log(blog?.image);
    drawerCtx.closeDrawer();

  }

  const getBlog = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blog?.id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === 'success') {
          console.log("blogs ", data.data)
          setBlog(data.data[0]);
          setOpen(true);         
          updateStatusClass();

          //here we will set the details
        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)

      });
  }
  useEffect(() => {
    getBlog();
  }, []);
  const updateStatusClass = () => {
    if (blog?.status === 'published') {
      setStatusClass('status-button published');
    } else {
      setStatusClass('status-button');
    }
  }


  const handleImageUpload = () => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: blog.id, blog: blog });
    modalCtx.openModal();
  }
  
  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }


  const updateStatus = async (blogId, status) => {



    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            // getAllBlogs()
            console.log("updated");
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const removeSingleTopic = (indexToRemove) => {
    blog?.topics.splice(indexToRemove, 1);
    console.log(blog);

  }
  //quill editor



  //functions
 

  const handleDateChange = (e) => {
    setDate(e.target.value); // Update state when the user edits the date
  };
  const toggleImageDirection = () => {
    setImageDirection(!imageDirection); // Toggle between portrait and landscape
  };
  const handleRemoveImage = () => {
    setImage(DefaultImage); // Set the image to DefaultImage
  };
  const deleteAuthor = async(id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${blog.id}/author/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })
        
        const res = await response.json();
        if (res.status == 'success') {
        setAuthors((prevAuthors) => prevAuthors.filter((author) => author.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const submitEditAuthor = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/author', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          id: blog?.id,
          authorId: id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const getAllAuthors = () => {

    fetch(process.env.REACT_APP_API_URI + '/v2/authors', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        console.log(response.json);
      }).then(data => {
        if (data.status === 'success') {
          setAuthors(data?.data)
          console.log(authors);
        }
      }).catch(err => console.error(err));
      
  }

  

  const DeleteAuthor = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/blogs/${devoId}/author/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value);
  }

  const handleSelectAuthor = (author) => {
    if (!selectedAuthors.some(el => el.id === author.id)) {
      submitEditAuthor(author.id)
      // setSelectedAuthors(prev => [...prev, author]);
    } else {
      return
    }
  }

  const handleFilterArray = (id) => {

    DeleteAuthor(blog?.id, id)
    const newArr = selectedAuthors.filter((arr) => {
      return arr.id !== id
    })
    setSelectedAuthors(newArr)
  }

  const changeEditorStatus = (e) => {
    if (e) {
      const quill = quillRef.current.getEditor();
      console.log(quill.getSelection());
      setEditor("myeditor active")
    } else {
      const quill = quillRef.current.getEditor();

      console.log(quill.getSelection());
      // quill.setSelection('');

      setEditor(" myeditor disable")
    }
  }

  // const DeleteTopic = async (devoId, id) => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/blogs/${devoId}/topic/${id}`,
  //       {
  //         method: 'DELETE',
  //         headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
  //       })

  //     const topicsResponse = await response.json();
  //     if (topicsResponse.status === 'success') {
  //       submitEditBlog()
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const DeleteTopic = async (id) => {
    modalCtx.setDetails('delete-topic',  {blogId:blog.id,id:id});
    modalCtx.openModal();

    // setTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== id));
    // try {
    //   const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${blog.id}/topic/${id}`,
    //     {
    //       method: 'DELETE',
    //       headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    //     })

    //   const res = await response.json();
    //   if (res.status == 'success') {
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };


  const DeleteTag = async (id) => {
    modalCtx.setDetails('delete-tag',  {blogId:blog.id,id:id});
    modalCtx.openModal();
  }

  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
    getBlog();
  }

  const updateStatusBlog = async (blogId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(blogId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          setUpdatingStatus(false)
          if (data.status === 'success') {
            submitEditBlog()

          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleVideoUrl = (url) => {
    let finalUrl
    let embedId

    if (url.includes('youtu.be' || 'youtube')) {
      embedId = url.split('/')
      finalUrl = `https://www.youtube.com/embed/${embedId[embedId.length - 1]}`
    }

    return finalUrl
  }


  const handleVideoBoolean = (e) => {
    if (e) {
      setBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  function showRegDate(unFormattedDate) {

    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  const handleMetaTagsBoolean = (e) => {
    if (e) {
      setMetaTagsOpen(true)
    } else {
      setMetaTagsOpen(false)
    }
  }

  const handleEditorToolBoolean = () => {
    if (editorToolOpen) {
      setEditorToolOpen(false)
    } else {
      setEditorToolOpen(true)
    }
  }

  const handleTagsTopicBoolean = () => {
    if (tagsTopicBarOpen) {
      setTagsTopicBarOpen(false)
    } else {
      setTagsTopicBarOpen(true)
    }
  }

  return (

    <div className="edit-blog-container">
      <div className="drawer blog-drawer-container">
        <div className="blog-drawer" style={editorToolOpen ? { gridTemplateRows: '160px 1fr 100px' } : { gridTemplateRows: '115px 1fr 100px' }}>

          <div className="blog-drawer-header">
            <header style={{ backgroundColor: "#E5E5E8",padding:"20px"}}>
              <h1 data-aos="fade-right" data-aos-delay="500" style={{color:"black"}}>
                < img className='back-button tool-button' onClick={(e) => {
                  closeDrawer();
                }} src={backbutton} alt='' />
                Edit Blog - {createBlogObj.title}</h1>
            </header>

            <div className="blog-webpage-clone">
              <div className="webpage-clone">
                <div className="webpage-link" style={{backgroundColor:"white", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)"}}>
                  <ArrowBackRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ArrowForwardRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ReplayRounded style={{ color: '#aaa' }} className='link-icon' />
                  <div className="link-container">
                    <span>kingdomempowered.com/blogs/</span>
                    <input type="text" name="slug" placeholder={createBlogObj.slug} onChange={(e) => setBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} />
                  </div>
                </div>
                <div className="title-tab" style={{backgroundColor:"white"}}>
                  <img src={Logo} alt="logo" />
                  <span>{createBlogObj.title}</span>
                  <CloseRounded style={{ color: '#aaa' }} />
                </div>

                <div className='action-buttons-div'>
                  <FormGroup>
                  <FormControlLabel
                      control={<ScreenOrientationBtn sx={{ m: 1 }} checked={metaTagsOpen} onChange={e => setMetaTagsOpen(e.target.checked)} />}
                      label="Meta Tags"
                    />
                  </FormGroup>
                  <button
                    className="btn-primary"
                    variant="contained"
                    component="label"
                    disabled={false}
                    onClick={(e) => submitEditBlog()}
                    style={{ backgroundColor: "#1359E4", color: "white" }}
                  >
                    {savingChanges ? (
                      <CircularProgress style={{ color: "white", background: "#1359E4" }} size={20} />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                  <button
                    className="btn-primary-border"
                    variant="outlined"
                    component="label"
                    disabled={false}
                    onClick={(e) => closeDrawer()}
                    style={{
                      backgroundColor: "#E5E5E8", 
                      color: "#1359E4",           // Text color
                      borderColor: "#1359E4",     
                    }}
                  >
                    Cancel
                  </button>

                </div>

                <div
                  className="meta-tags-container"
                  style={{
                    backgroundColor: "#F6F7FA",
                    flexDirection: 'row',
                    gap: '15px', // Adjust spacing between the fields
                    alignItems: 'center',
                    zIndex: 1,
                    display: metaTagsOpen ? "flex" : "none",
                   
                  }}
                >
                  {/* Conditionally render the TextFields based on metaTagsOpen */}
                  {metaTagsOpen && (
                    <>
                    <TextField
                      style={{}}
                      name="metaDescription"
                      label="Meta Description"
                      value={createBlogObj.metaDescription}
                      onChange={(e) =>
                        setBlogObj((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }))
                      }
                      placeholder="Meta Description"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                    />
                    <TextField
                      style={{  }}
                      name="metaKeywords"
                      label="Meta Keywords"
                      value={createBlogObj.metaKeywords}
                      onChange={(e) =>
                        setBlogObj((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }))
                      }
                      placeholder="Meta Keywords"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                    />
                  </>
                  )}
                </div>

              </div>
            </div>
            <div className="editor-toolbar-container" style={{
               marginTop: metaTagsOpen ? "130px" : "0px",position: "relative", zIndex: 2 ,background:"#FFFFFF 0% 0% no-repeat padding-box",padding:"10px",height:"64px"
            }}>
              <div className="editor-toolbar-control">
                <KeyboardArrowDownRounded style={editorToolOpen ? { transform: 'rotateX(180deg)' } : null} onClick={() => handleEditorToolBoolean()} />
              </div>
              <EditorToolbar editorToolOpen={editorToolOpen} styles={{}} />
            </div>
          </div>

          <div className='blog-edit-section'
            style={{
              margin: metaTagsOpen ? "250px 10% 30px 10%" : "100px 10% 30px 10%",
            }} >
            <div className='editor-div'>

              <div className="image-and-title-container" style={{flexDirection: !imageDirection ? "column" : "row",}}>
                <div className='image-container' style={containerStyles}>
                    <div className='image-change-btn'>
                    {imageDirection ? (
                      <StayCurrentLandscapeIcon 
                        className="landscape-btn" 
                        onClick={toggleImageDirection} 
                      />
                    ) : (
                      <StayCurrentPortraitIcon 
                        className="portrait-btn" 
                        onClick={toggleImageDirection} 
                      />
                    )}
                    </div>

                    <div className="img">
                    <img
                      className={`post-image ${image === DefaultImage ? "placeholder" : ""}`}
                      src={image}
                      alt={blog?.title || "Blog Post"}
                    />
                    <div className="upload-image-buttons" >
                      <button className="btn-primary" onClick={handleImageUpload}>
                        <PublishRounded className="buttons" />
                        {image !== DefaultImage ? "Update Image" : "Add Image"}
                      </button>

                      {/* Existing Remove Image Button */}
                      {image !== DefaultImage && (
                        <button className="btn-primary-border" onClick={handleRemoveImage}>
                          <HighlightOffRounded className="buttons" /> Remove Image
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className='title-and-author-container' style={{width: !imageDirection ? "100%" : "60%",}}>
                  <div className='title-and-author'>
                    <div className="blog-author-and-date">
                      <input
                        type="text"
                        value={date}
                        onChange={handleDateChange}
                        placeholder="Enter Date"
                        className="date-editor"
                      />
                    </div>

                    <div className='author-row'>
                      {authors.map((author) => (
                        <div key={author.id} className="author-container">
                          <img
                            src={author.avatar || "https://d33byq9npfy6u9.cloudfront.net/2/2022/05/03184712/Glenn_Youngkin_Headshot-e1651603656923.jpg"}
                            className="author-image"
                            alt={author.fullName}
                          />
                          <span className="blog-author" onClick={getAllAuthors}>
                            {author.fullName}
                          </span>
                          <span className="remove-blog-author">
                            <CloseRounded
                              style={{ height: "15px", width: "15px", cursor: "pointer" }}
                              onClick={() => deleteAuthor(author.id)}
                            />
                          </span>
                        </div>
                      ))}
                      
                      <div className='author-tag' onClick={() => handleModal('edit-blog-author', blog)}><AddRounded className='add-author' /> Author</div>
                    </div>

                    <div className="title-container">
                      {/* <div contentEditable={true} className='title-editor' role="textbox" spellcheck="true" onInput={(e) => } placeholder="Title" >{createBlogObj.title}</div> */}
                      <div ref={titleRef} contentEditable={true} className='title-editor' onInput={handleTitleInput}></div>
                    </div>

                    <div className='content-container'>
                      <div className='text-editor-container'>


                        <ReactQuill className={editor} ref={quillRef}
                          theme="snow"
                          value={createBlogObj.post}
                          onChangeSelection={(e) => {
                            changeEditorStatus(e)
                          }}
                          onChange={(e) => setBlogObj((prev) => ({ ...prev, post: e }))}
                          placeholder={"Write something awesome..."}
                          modules={modules}
                          formats={formats}
                        />
                        {/* <ReactQuill className='myeditor' modules={{
                            toolbar: false  // hide the default toolbar
                          }} defaultValue='Blog Post' theme="snow" name="post" value={createBlogObj.post} onChange={(e) => setBlogObj((prev) => ({ ...prev, post: e }))} /> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='tags-container'>
            <div className='topic-row'>
              <div className='single-tag' onClick={() => handleModal('edit-blog-topics', blog)}><AddRounded className='add-author' /> Topics</div>
              <div className='topic-collection'>
                {
                  topics.map((topic, index) => {
                    return (
                      <div key={topic.id} className="single-tag">
                        {topic.title}
                        <CloseRounded
                          className="remove-author"
                          onClick={() => DeleteTopic(topic.id)}
                        />
                      </div>
                    );
                  })
                }

              </div>
            </div>

            <div className='tags-row'>
              <div className='single-tag' onClick={() => handleModal('edit-blog-tags', blog)}><AddRounded className='add-author' /> Tag</div>
              <div className='tags-collection'>
              {
                tags.map((tag, i) => {
                  return (
                    <div key={tag.id} className="single-tag">
                      {tag.title}
                      <CloseRounded
                        className="remove-author"
                        onClick={() => DeleteTag(tag.id)}
                      />
                    </div>
                  );
                })
              }
              </div>
              {/* <div className='single-tag' onClick={null}><CloseRounded className='add-author' onClick={() => null} /> Prayer</div> */}
            </div>

            <div className='status-container'>
              <div className='status-date'>
                Created On {date}
              </div>
              <div className='status'>
                <p>Status - </p> 
                <div className="author-tags" onClick={() => openPublishModal(blog)}>
                  <span className={statusClass}>{blog?.status.toUpperCase()}</span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <SnackbarContent style={{
          backgroundColor: 'teal',
          borderRadius: "10px",
          color: "white",
        }}
          message={<span id="client-snackbar">Post Saved</span>}
        />
      </Snackbar>
    </div>
  );
}

export default EditBlogDrawer;

